import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "container", "tags", "data", "suggestions"];

  // So "click->tags#active" won't error
  active() {
    this.inputTarget.focus();
  }

  initialize() {
    this.fetchTags();

    // Initialize tags from data attribute
    const initialTags = this.data.get("tagCollection");
    if (initialTags) {
      const tagArray = JSON.parse(initialTags);
      this.data.set("tagCollection", JSON.stringify(tagArray));
      this.dataTarget.value = tagArray.join(", ");
      this.renderTags();
    }

    // ---- Outside-click logic ----
    document.addEventListener("click", (e) => {
      // If we clicked outside container + suggestions
      if (
        !this.containerTarget.contains(e.target) &&
        this.suggestionsTarget &&
        !this.suggestionsTarget.contains(e.target)
      ) {
        // If leftover text exists, finalize it
        if (this.inputTarget.value.trim().length > 0) {
          this.setTag(this.inputTarget.value);
        }
        this.containerTarget.classList.remove(
          "ring-cyan-500",
          "border-cyan-500",
          "ring-1"
        );
      } else {
        // Inside container or suggestions
        this.containerTarget.classList.add(
          "ring-cyan-500",
          "border-cyan-500",
          "ring-1"
        );
      }
    });

    // ---- Focus / Blur styling ----
    document.addEventListener("focusin", (e) => {
      if (this.inputTarget === e.target) {
        this.containerTarget.classList.add(
          "ring-cyan-500",
          "border-cyan-500",
          "ring-1"
        );
      }
    });

    document.addEventListener("focusout", (e) => {
      setTimeout(() => {
        if (
          this.inputTarget === e.target &&
          this.suggestionsTarget &&
          !this.suggestionsTarget.contains(document.activeElement)
        ) {
          if (this.inputTarget.value.trim().length > 0) {
            this.setTag(this.inputTarget.value);
          }
          this.containerTarget.classList.remove(
            "ring-cyan-500",
            "border-cyan-500",
            "ring-1"
          );
          this.suggestionsTarget.innerHTML = "";
        }
      }, 100);
    });

    // ---- Keydown for Enter/Tab/Comma ----
    // Using keydown so we can prevent tabbing away.
    this.inputTarget.addEventListener("keydown", (event) => {
      // Pressing comma => event.key === ","
      if (event.key === "Enter" || event.key === "Tab" || event.key === ",") {
        event.preventDefault();
        event.stopPropagation();

        const trimmedValue = this.inputTarget.value.trim();
        if (trimmedValue.length > 0) {
          this.setTag(trimmedValue);
        } else {
          this.inputTarget.value = "";
        }
      }
    });
  }

  connect() {
    this.renderTags();
  }

  // ---- Fetch all possible tags (for suggestions) ----
  fetchTags() {
    fetch("/tags.json")
      .then((response) => response.json())
      .then((data) => {
        // e.g. data = [{ name: "Ruby" }, { name: "Rails" }, ...]
        this.allTags = data.map((t) => t.name);
      })
      .catch((error) => {
        console.error("Error fetching tags:", error);
      });
  }

  // Show suggestions as user types
  inputTag(event) {
    const query = event.target.value;
    this.showSuggestions(query);
  }

  showSuggestions(query) {
    if (!query || query.trim().length === 0) {
      this.suggestionsTarget.innerHTML = "";
      return;
    }

    const lowerCaseQuery = query.trim().toLowerCase();
    const currentTags = this.tags.map((t) => t.toLowerCase());

    // Filter out anything already selected (case-insensitive)
    const matches = (this.allTags || []).filter(
      (tag) =>
        tag.toLowerCase().includes(lowerCaseQuery) &&
        !currentTags.includes(tag.toLowerCase())
    );

    // We rely on event.currentTarget in selectSuggestion() for full text
    this.suggestionsTarget.innerHTML = matches
      .map(
        (tag) => `
          <li
            data-action="mousedown->tags#selectSuggestion"
            class="cursor-pointer block truncate px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
          >
            ${tag}
          </li>
        `
      )
      .join("");
  }

  /**
   * Called when user mouses down on a suggestion (see mousedown->tags#selectSuggestion).
   * We do mousedown instead of click to avoid outside-click conflicts.
   */
  selectSuggestion(event) {
    // The triple stop to prevent outside click from firing
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();

    const tag = event.currentTarget.textContent.trim();
    this.setTag(tag);
    this.inputTarget.value = "";
    this.suggestionsTarget.innerHTML = "";
  }

  setTag(tag) {
    this.tags = tag;
    this.inputTarget.value = "";
    this.renderTags();
  }

  backspaceTag(event) {
    // If input is empty, remove last tag
    if (event.key === "Backspace" && this.inputTarget.value === "") {
      this.removeLastTag();
    }
  }

  renderTags() {
    fetch("/tags/template.html")
      .then((response) => response.text())
      .then((html) => {
        const template = document.createElement("template");
        template.innerHTML = html;
        this.tagsTarget.innerHTML = "";
        this.tags.forEach((tag) => {
          const tagElem = template.content.cloneNode(true);
          const tagText = tagElem.querySelector("p");
          tagText.textContent = tag;
          this.tagsTarget.appendChild(tagElem);
        });
      })
      .catch((error) => {
        console.error("Error rendering tags:", error);
      });
  }

  removeTag(event) {
    const tag =
      event.currentTarget.parentElement.querySelector("p").textContent;
    if (tag !== null && tag !== undefined) {
      const tags = this.tags;
      const index = tags.indexOf(tag);
      if (index > -1) {
        tags.splice(index, 1);
        this.updateTags = tags;
        this.renderTags();
      }
    }
  }

  removeLastTag() {
    const tags = this.tags;
    if (tags.length > 0) {
      tags.splice(tags.length - 1, 1);
      this.updateTags = tags;
      this.renderTags();
    }
  }

  // Keep Stimulus data + hidden input in sync
  set updateTags(tags) {
    this.data.set("tagCollection", JSON.stringify(tags));
    this.dataTarget.value = tags.join(", ");
  }

  // -------------- THE KEY UNIFICATION CHANGE ---------------
  set tags(value) {
    // 1. Keep uppercase/spaces, remove commas so we don't auto-split on them
    let inputTag = value.trim().replace(/,/g, "");
    const tags = this.tags;
    const lowerInputTag = inputTag.toLowerCase();

    // 2. If there's an EXACT suggestion ignoring case, unify to that suggestion's real case
    const matchedSuggestion = (this.allTags || []).find(
      (sugg) => sugg.toLowerCase() === lowerInputTag
    );
    if (matchedSuggestion) {
      // Use the suggestion's original case
      inputTag = matchedSuggestion;
    }

    // 3. Avoid duplicates in a case-insensitive way
    if (!tags.map((t) => t.toLowerCase()).includes(lowerInputTag)) {
      tags.push(inputTag);
      this.data.set("tagCollection", JSON.stringify(tags));
      this.dataTarget.value = tags.join(", ");
    }
  }

  get tags() {
    const storedTags = this.data.get("tagCollection");
    return storedTags ? JSON.parse(storedTags) : [];
  }
}
